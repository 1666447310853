import React from "react";
import {Checkbox} from "antd";
import {db} from "./firebase";

export const SitesItem = ({site}) => {
  const onChange = async (e) => {
    const isActive = e.target.checked;

    await db.collection('sites').doc(site.key).update({isActive})
  };

  return (
    <>
      <Checkbox onChange={onChange} checked={site.isActive || false}>{site.url || 'ERROR: no url'}</Checkbox>
    </>
  )

}
