import React, {useEffect, useState} from "react";
import {SitesItem} from "./SiteItem";
import {db} from "./firebase";
import {Button, Divider, Input, Space} from "antd";

export const SitesList = () => {
  const [newSite, setNewSite] = useState("");
  const [sites, setSites] = useState([]);

  useEffect(() => {
    db.collection('sites').onSnapshot((snap) => {
      const docs = []
      for (const doc of snap.docs) {
        const data = doc.data()
        const key = doc.id

        docs.push({
          ...data,
          key
        })
      }
      setSites(docs)
    })
  }, [])

  const saveNewSiteToDB = async () => {
    if (newSite) {
      await db.collection('sites').add({
        url: newSite,
        isActive: true
      })
      setNewSite('')
    }
  }

  return (
    <>
      <Space>
        <Input placeholder='Добавить сайт' value={newSite} onChange={(e) => setNewSite(e.target.value)}/>
        <Button onClick={() => saveNewSiteToDB()}>Добавить</Button>
      </Space>
      <Divider/>
      <Space direction="vertical">
        {sites.map((site) => <SitesItem key={site.key} site={site}/>)}
      </Space>
    </>
  )

}
