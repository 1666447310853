import firebase from 'firebase/app';
import 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCbqiAZdgp1uccdb0O-c1urLsSDoN9MDOQ",
    authDomain: "zoya-search-app.firebaseapp.com",
    projectId: "zoya-search-app",
    storageBucket: "zoya-search-app.appspot.com",
    messagingSenderId: "302264396322",
    appId: "1:302264396322:web:4e06d19335762dfe9d787b"
};

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore();
