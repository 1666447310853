import React, {useState, useEffect} from 'react';
import {Button, Col, Input, Row, Space} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import './App.css';
import {Layout} from 'antd';
import {SitesList} from "./SitesList";
import {db} from "./firebase";

const {Content} = Layout;

function App() {
  const [productName, setProductName] = useState("");
  const [sites, setSites] = useState([]);

  useEffect(() => {
    db.collection('sites').where("isActive", "==", true).onSnapshot((snap) => {
      const docs = []
      for (const doc of snap.docs) {
        const data = doc.data()
        const key = doc.id

        docs.push({
          ...data,
          key
        })
      }
      setSites(docs)
    })
  }, [])

  const getSitesStr = (sites) => {
    const arr = sites.map((site) => `+site:${site.url}`);

    return arr.join('+OR')
  }
  return (
    <Layout className="App">
      <Content className="site-layout-content">
        <Row>
          <Col span={12}>
            <Space direction="vertical" align="center" className='search_side'>
              <Input placeholder='Введите наименование товара'
                     style={{ width: '95%' }}
                     size="large"
                     allowClear
                     onChange={e => setProductName(e.target.value)}/>
              <Button
                shape="round"
                href={`https://www.google.com/search?q=${productName}+AND${getSitesStr(sites)}`}
                type="primary"
                icon={<SearchOutlined/>}
                target="_blank"
              >
                Поиск
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <SitesList/>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default App;
